import { Link } from 'gatsby'
import React, { Component } from 'react'

import Logo from '../assets/images/logo.svg'

import { Instagram, Phone } from '../components/icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    let nextState = currentScroll > 0
    if (nextState !== scrolled) this.setState({ scrolled: nextState })
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }
    let headerClass = `header`
    if (offCanvas) headerClass += ' header--inverse'

    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Eisen Property' className='logo' {...props}>
              <img src={Logo} alt='Eisen Property' />
            </Link>
            <nav className="header__nav">
              <ul>
                <li><Link to='/about-us' {...props}>About us</Link></li>
                <li><Link to='/services' {...props}>Services</Link></li>
                <li className='has-sub'>
                  <Link to='/projects' {...props}>Campaigns</Link>
                  <ul className='sub-menu'>
                    <li><Link to='/projects/current' {...props}>Current</Link></li>
                    <li><Link to='/projects/past' {...props}>Past</Link></li>
                  </ul>
                </li>
                <li><Link to='/contact-us' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <nav className='header__social'>
              <ul>
                <li><a href='https://www.instagram.com/eisenproperty/' target='_blank' rel='noopener noreferrer'><Instagram color={'#FFF'} /></a></li>
                <li><a href='tel:1300185738' target='_blank' rel='noopener noreferrer'><Phone color={'#FFF'} /> <span>1300 185 738</span></a></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className="lines"></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className="container">
            <nav>
              <ul>
                <li><Link to='/about-us' {...props}>About us</Link></li>
                <li><Link to='/services' {...props}>Services</Link></li>
                <li className='has-sub'>
                  <Link to='/projects' {...props}>Projects</Link>
                  <ul className='sub-menu'>
                    <li><Link to='/projects/current' {...props}>Current</Link></li>
                    <li><Link to='/projects/past' {...props}>Past</Link></li>
                  </ul>
                </li>
                <li><Link to='/contact-us' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

Header.defaultProps = {
  data: {},
}

export default Header
