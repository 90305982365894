import React, { Component } from 'react'
import { Link } from 'gatsby'

import FooterLogo from '../assets/images/footer-logo.svg'

import { Instagram } from '../components/icons'

class Footer extends Component {
  render() {
    return (
      <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__left'>
              <Link to='/' title='Eisen Property' className='logo'>
                <img src={FooterLogo} alt="Eisen Property" />
              </Link>
              <p>© Copyright Eisen Property {new Date().getFullYear()} All Rights Reserved.</p>
            </div>
            <div className='footer__right'>
              <nav className='footer__social'>
                <ul>
                  <li><a href='https://instagram.com' target='_blank' rel='noopener noreferrer'><Instagram color={'#FFF'} /></a></li>
                </ul>
              </nav>
              <p>Images <a href='//benhosking.com.au' target='_blank' rel='noopener noreferrer'>Ben Hosking</a></p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
